import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import {useUserAuth} from "./UseAuthContext";

 

const PhoneSignUp = () => {
              
  const [error , setError] = useState("");
  const [number , setNumber] = useState("");
  const [flag , setFlag] = useState(false);
  const [otp , setOtp] = useState("");
  const [result , setResult] = useState(null);
  const { setUpRecaptha } = useUserAuth();
  const navigate = useNavigate();

  let appVarificationDissabledForTesting;

  const getOtp = async (e) => {
    e.preventDefault();
    console.log(number);
    setError("");
    if (number === "" || number === undefined)
      return setError("Please enter a valid phone number!");
    try {
      const response = await setUpRecaptha(number);
      setResult(response);
     if(!result){
      return <div>Loading.....</div>
     }
      appVarificationDissabledForTesting=result.appVarificationDissabledForTesting;
     

      setFlag(true);
    } catch (err) {
      setError(err.message);
    }  
  };


  const verifyOtp = async (e) => {
    e.preventDefault();
    setError("");
    if (otp === "" || otp === null) return;
    try {
      await result.confirm(otp);
      navigate("/home");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <>
      <div className="p-4 box">
        <h2 className="mb-3">Firebase Phone Auth</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={getOtp} style={{ display: !flag ? "block" : "none" }}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <PhoneInput
              defaultCountry="IN"
              value={number}
              onChange={setNumber}
              placeholder="Enter Phone Number"
            />
            <div id="recaptcha-container"></div>
          </Form.Group>
          <div className="button-right">
            <Link to="/">
              <Button variant="secondary">Cancel</Button>
            </Link>
            &nbsp;
            <Button type="submit" variant="primary">
              Send Otp
            </Button>
          </div>
        </Form>
       {
        appVarificationDissabledForTesting?<>
        
        <Form onSubmit={verifyOtp} style={{ display: flag ? "block" : "none" }}>
          <Form.Group className="mb-3" controlId="formBasicOtp">
            <Form.Control
              type="otp"
              placeholder="Enter OTP"
              onChange={(e) => setOtp(e.target.value)}
            />
          </Form.Group>
          <div className="button-right">
            <Link to="/">
              <Button variant="secondary">Cancel</Button>
            </Link>
            &nbsp;
            <Button type="submit" variant="primary">
              Verify
            </Button>
          </div>
        </Form>
        </>:''
       }

      </div>
    </>
  );
};

export default PhoneSignUp;
// import React, { useState, useEffect } from 'react';
// import firebase from 'firebase/app';
// import 'firebase/auth';

// const PhoneSignUp = () => {
//   const [phoneNumber, setPhoneNumber] = useState('');
//   const [verificationCode, setVerificationCode] = useState('');
//   const [user, setUser] = useState(null);
//   const [confirmationResult,setConfirmationResult]=useState('');

//   // Initialize Firebase
//   useEffect(() => {
//     const firebaseConfig = {
//       apiKey: "AIzaSyC9Q_wbGz30T_SUw_SdQp05Czf0D7_4AXc",
//       authDomain: "laundry-2-51d31.firebaseapp.com",
//       projectId: "laundry-2-51d31",
//       storageBucket: "laundry-2-51d31.appspot.com",
//       messagingSenderId: "207910540560",
//       appId: "1:207910540560:web:6c7d784c9129fe0fd80195",
//       measurementId: "G-ZSD3N9PTKL"
//     };

//     if (!firebase.apps.length) {
//       firebase.initializeApp(firebaseConfig);
//     }
//   }, []);

//   // Function to send OTP to the user's phone number
//   const sendOTP = async () => {
//     try {
//       const confirmationResult = await firebase.auth().signInWithPhoneNumber(phoneNumber);
//       // Store the confirmation result for later use
//       setVerificationCode('');
//       setConfirmationResult(confirmationResult);
//     } catch (error) {
//       console.error('Error sending OTP:', error);
//     }
//   };

//   // Function to sign in with OTP
//   const signInWithOTP = async () => {
//     try {
//       const credential = firebase.auth.PhoneAuthProvider.credential(confirmationResult.verificationId, verificationCode);
//       await firebase.auth().signInWithCredential(credential);
//       const currentUser = firebase.auth().currentUser;
//       setUser(currentUser);
//     } catch (error) {
//       console.error('Error signing in with OTP:', error);
//     }
//   };

//   return (
//     <div>
//       <h2>Phone OTP Login</h2>
//       {user ? (
//         <div>
//           <p>Welcome, {user.displayName}!</p>
//           <p>User ID: {user.uid}</p>
//         </div>
//       ) : (
//         <div>
//           <input
//             type="text"
//             placeholder="Enter phone number"
//             value={phoneNumber}
//             onChange={(e) => setPhoneNumber(e.target.value)}
//           />
//           <button onClick={sendOTP}>Send OTP</button>
//           {confirmationResult && (
//             <div>
//               <input
//                 type="text"
//                 placeholder="Enter verification code"
//                 value={verificationCode}
//                 onChange={(e) => setVerificationCode(e.target.value)}
//               />
//               <button onClick={signInWithOTP}>Sign In</button>
//             </div>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

// export default PhoneSignUp;
