import React, { useEffect, useState } from 'react';
import { useReactToPrint } from 'react-to-print';


import Sidebar from '../Sidebar';
import OrderTag from './Tags';

const LaundryTag = () => {

  const [order,setOrder]=useState([]);
  
  const fetchOrders= async()=>{
    const response= await fetch('http://localhost:3007/tag-print',{
      method:"GET",
      headers:{
        'content-type':'application/json'
      }
    });
    var data= await response.json();
    setOrder(data);
  }

  useEffect(()=>{
    fetchOrders();
  })

  return <>

    <div style={{position:'fixed',marginTop:'-2%'}}>
      <Sidebar/>
    </div>
    <div  className='tagbox'>
      <div  className='taghead' style={{marginLeft:'45%',fontSize:'4vw',fontWeight:'bolder'}}>TAG PRINT</div>
  {
     order.map(odrs=>{
       return <>
       <OrderTag order={odrs}/>
        
       </>
     })
  }
   </div>
    </>

};

export default LaundryTag;
