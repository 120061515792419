import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
// import './tagprint.css'

const OrderTag = ({ order }) => {
  const componentRef = useRef();

  return (
      
    <div className='tags' style={{marginLeft:'30%',marginTop:'5%'}}>
      <p style={{color:'green'}} className='id'>Order ID: {order._id}</p>
      <p className='cname'>Customer Name: {order.name}</p>
      

      <ReactToPrint
        trigger={() => <button style={{width:'90%',backgroundColor:'blue',color:'white'}}>PRINT ORDER TAG</button>}
        content={() => componentRef.current}
      />
      <div style={{ display: 'none' }} key={order._id}>
        <div ref={componentRef} style={{width:'1.5in',height:'2in',textAlign:'center',color:'black',marginLeft:'40%'}}>
          {order.Type==='bycloth'?
          order.Service.map(odr=>{
            return <> 
            <div key={odr.name} style={{borderBottom:'1px solid black',marginTop:'3%'}} >
              <p> {(order._id).substr(19,25)}</p>
              <p> {order.name}</p>
              <p>{order.PickUpDate}</p>
              <p> {odr.services==='DryCleaning'?'Dc':odr.services==='SteamIron'?'Si':odr.services==='PremimumLaundry'?'Pl':'Lw'}/{odr.category==='women'?'w':odr.category==='men'?'m':odr.category==='kids'?'k':odr.category==='household'?'h':'l'}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{odr.quantity}</p>
              <p>{odr.name}</p>
            </div>
          </>  
          }):
          order.Service.map(odr=>{
            return <> 
            <div key={odr.name} style={{borderBottom:'1px solid black',marginTop:'3%'}} >
              <p> {(order._id).substr(19,25)}</p>
              <p> {order.name}</p>
              <p>{order.PickUpDate}</p>
              <p> {odr.services==='DryCleaning'?'Dc':odr.services==='SteamIron'?'Si':odr.services==='PremimumLaundry'?'Pl':'Lw'}/{odr.category==='women'?'w':odr.category==='men'?'m':odr.category==='kids'?'k':odr.category==='household'?'h':'L'}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{odr.weight} kg</p>
              <p>order by kg</p>
            </div>
          </>  
          })
          
          }
      {/* {order.Service.map(odr=>{
        return <> 
        <div key={odr.name} style={{borderBottom:'1px solid black',marginTop:'3%'}} >
          <p> {(order._id).substr(19,25)}</p>
          <p> {order.name}</p>
          <p>{order.PickUpDate}</p>
          <p> {odr.services==='DryCleaning'?'Dc':odr.services==='SteamIron'?'Si':odr.services==='PremimumLaundry'?'Pl':'Lw'}/{odr.category==='women'?'w':odr.category==='men'?'m':odr.category==='kids'?'k':odr.category==='household'?'h':'l'}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{odr.quantity}</p>
          <p>{odr.name}</p>

        </div>
      </>  
      })} */}
        </div>
      </div>
    </div>
  );
};

export default OrderTag;