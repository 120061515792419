import React, { useState } from 'react'
import './Message.css'
import { useNavigate, useParams } from 'react-router'
import { Line } from 'recharts';
import { Link } from 'react-router-dom';

const Message = () => {
    const [message,setMessage]=useState('');
    const param=useParams();
    const userid=param.userid;
    const orderid=param.orderid;
    const name=param.name.toLocaleUpperCase()

    const navigate=useNavigate();

    const handleMessageChange=(ev)=>{
        setMessage(ev.target.value)
    }

    const handleMessage=async()=>{
        fetch(`http://localhost:3007/sendmessage/${userid}/${orderid}`,{
            method:"POST",
            headers:{
              'content-type':'application/json'
            },
            body:JSON.stringify({message:message})
          })
          .then(response=>{
            response.json();
        })
        .then(result=>{
            console.log(result);
            navigate('/billing');
          })
          .catch(err=>{
            console.log(err);
          })

    }
  return <>
  <Link to='/billing'>
  <div className='topbar'>Back</div>
  </Link>
  <div style={{color:'green'}} className='head'>SEND MESSAGE TO {name}</div>
    <div className='msgbox'>
        <div style={{position:'absolute'}}>MESSAGE</div>
        <textarea  cols="50" rows="10" style={{border:'1px solid black'}} onChange={handleMessageChange} value={message}></textarea>
        <button style={{backgroundColor:'green',color:'white'}} className='send' onClick={handleMessage}>SEND</button>
    </div>
  
  </>
}

export default Message
