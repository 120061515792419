import React from 'react'

import { useState,useEffect } from 'react';

import Sidebar from './Sidebar';
import './orders.css'
import './Admin.css'
import { Modal } from 'rsuite';
import { useModelState } from '../Home/Custom_hooks/custom';
import { Link } from 'react-router-dom';

const Billing = () => {
    const {isOpen,open,close}=useModelState();
    const [orders,setOrders]=useState([]);
    const [orderid,setorderid]=useState();
    const [orderstatus,setorderstatus]=useState('Pending');

    const fetchOrders= async ()=>{
        const response= await fetch(`http://localhost:3007/order-billing` ,{
            method:"GET",
            headers:{
                'content-type':'application/json'
            }
        });
        let data= await response.json();
        setOrders(data);
    }
    useEffect(()=>{
       fetchOrders()
    })

    const handleOrderStatusChange=async(id,value)=>{
          try{
            const response= await fetch(`http://localhost:3007/changeOrderStatus/${orderid}/${value}` ,{
                method:"POST",
                headers:{
                    'content-type':'application/json'
                }
            });
            let data= await response.json();
            console.log(data);
        }
        catch(err){
            console.log(err)
        }
    }

    const handleorderid=(id)=>{
        setorderid(id)
    }
  return <>

    {/* <Modal  open={isOpen} onClose={close}>
        <Modal.Header>
            <Modal.Title className='statusHead' >Change order status</Modal.Title>
        </Modal.Header>
        <Modal.Body className='statusbox'>
             <button className='statusbtns' value='Pending' onClick={handleOrderStatusChange}>Pending</button>
             <button className='statusbtns' value='Order Confirmed' onClick={handleOrderStatusChange}>Confirm Order</button>
             <button className='statusbtns' value='Order In Progress' onClick={handleOrderStatusChange}>Order in Progress</button>
             <button className='statusbtns' value='order Completed' onClick={handleOrderStatusChange}>Completed</button>
             <button className='statusbtns' value='Out For Delivery ' onClick={handleOrderStatusChange}>Out for delivery</button>
             <button className='statusbtns' value='Order Delivered' onClick={handleOrderStatusChange}>Order delivered</button>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
    </Modal> */}
  <div style={{position:'fixed',marginTop:'-2%'}}>
   <Sidebar/>
  </div>
  <div className='userbox'>
  <div className='history'>ORDERS</div>
      {orders.map(order=>{
          return <>
           <div key={order._id} className='orderbox'>
           <Modal  open={isOpen} onClose={close}>
        <Modal.Header>
            <Modal.Title className='statusHead' >Change order status</Modal.Title>
        </Modal.Header>
        <Modal.Body className='statusbox'>

             <button className='statusbtns' value='Pending' onClick={()=>handleOrderStatusChange(order._id,'Pending')} id={order._id} style={order.orderStatus==='Pending'?{backgroundColor:'green'}:{}}>Pending</button>
             <button className='statusbtns' value='Order Confirmed' onClick={()=>handleOrderStatusChange(order._id,'Order Confirmed')} id={order._id} style={order.orderStatus==='Order Confirmed'?{backgroundColor:'green'}:{}}>Confirm Order</button>
             <button className='statusbtns' value='Order In Progress' onClick={()=>handleOrderStatusChange(order._id,'Order In Progress')} id={order._id} style={order.orderStatus==='Order In Progress'?{backgroundColor:'green'}:{}}>Order in Progress</button>
             <button className='statusbtns' value='Laundry Completed' onClick={()=>handleOrderStatusChange(order._id,'Laundry Completed')} id={order._id} style={order.orderStatus==='Laundry Completed'?{backgroundColor:'green'}:{}}>Completed</button>
             <button className='statusbtns' value='Out For Delivery ' onClick={()=>handleOrderStatusChange(order._id,'Out For Delivery')} id={order._id} style={order.orderStatus==='Out For Delivery'?{backgroundColor:'green'}:{}}>Out for delivery</button>
             <button className='statusbtns' value='Order Delivered' onClick={()=>handleOrderStatusChange(order._id,'Order Delivered')} id={order._id} style={order.orderStatus==='Order Delivered'?{backgroundColor:'green'}:{}}>Order delivered</button>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
    </Modal>


           <div>
            <table style={{color:'black',marginTop:'2%'}}>
        {/* <tr>
        </tr> */}
        <tr>
            <td style={{border:'.1vw solid blue'}}>OREDR ID</td>
            <td style={{border:'.1vw solid blue'}}>{order._id}</td>
        </tr>
        <tr>
            <td style={{backgroundColor:"lightskyblue",border:'.1vw solid blue'}}>CUSTOMER NAME</td>
            <td style={{backgroundColor:'lightgreen',border:'.1vw solid green'}}>{order.name}</td>
        </tr>
        <tr>
            <td style={{backgroundColor:"lightskyblue",border:'.1vw solid blue'}}>PICK UP DATE</td>
            <td style={{backgroundColor:'lightgreen',border:'.1vw solid green'}}>{order.PickUpDate}</td>
        </tr>
        <tr>
            <td style={{backgroundColor:"lightskyblue",border:'.1vw solid blue'}}>PICK UP TIME</td>
            <td style={{backgroundColor:'lightgreen',border:'.1vw solid green'}}>{order.PickUpTime}</td>
        </tr>
        <tr>
            <td style={{backgroundColor:"lightskyblue",border:'.1vw solid blue'}}>SERVICES USED</td>
            <td style={{backgroundColor:'lightgreen',border:'.1vw solid green'}}>{order.Type==='bycloth'?order.Service.map(serv=>{
                return <>
                 <div key={serv.name}>{serv.services} - {serv.name}({serv.quantity})</div>
                </>
            }):
            order.Service.map(serv=>{
                return <>
                <div key={serv.name}>{serv.service} by kg  - {serv.weight} kg</div>
                </>

            })
            }
            </td>
        </tr>
        <tr>
            <td style={{backgroundColor:"lightskyblue",border:'.1vw solid blue'}}>TOTAL PRICE</td>
            <td style={{backgroundColor:'lightgreen',border:'.1vw solid green'}}>Rs. {order.price}</td>
        </tr>
        <tr>
            <td style={{backgroundColor:"lightskyblue",border:'.1vw solid blue'}}>PAYMENT TYPE</td>
            <td style={{backgroundColor:'lightgreen',border:'.1vw solid green'}}>{order.PaymentMode}</td>
        </tr>
        <tr>
            <td style={{backgroundColor:"lightskyblue",border:'.1vw solid blue'}}>ORDER STATUS</td>
            <td style={{backgroundColor:'lightgreen',border:'.1vw solid green'}}>{order.orderStatus}</td>
        </tr>
        <tr>
            <button onClick={open} onMouseEnter={()=>handleorderid(order._id)} id={order._id} className='orderStatusBtn' style={{backgroundColor:'blue',color:'white'}}>Change Order Status</button>
            <Link style={{textDecoration:'none'}} to={`/sendmessage/${order.userId}/${order._id}/${order.name}`}>Send a message to {order.name}{' >'}</Link>
        </tr>
    </table>
              
           {/* <table>
      <tbody>
          <tr>
              <td className='td'>Order Id</td>
              <td className='orderdata'>{order._id}</td>
          </tr>
          <tr>
              <td  className='td'>Service</td>
              <td className='orderdata'>{order.Service}</td>
          </tr>
          <tr>
              <td  className='td'>Pick Up Date</td>
              <td className='orderdata'>{order.PickUpDate}</td>
          </tr>
          <tr>
              <td  className='td'>Pick Up Time</td>
              <td className='orderdata'>{order.PickUpTime}</td>
          </tr>
          <tr>
              <td  className='td'>Payment Status</td>
              <td className='orderdata'>{order.PaymentMode?'Pending':'Payment Completed'}</td>
          </tr>
          <tr>
              <td  className='td'>Price</td>
              <td className='orderdata'>{order.price}</td>
          </tr>
      </tbody>
  </table> */}
           </div>
           </div>
           
          </>
      })}
  </div>
  </>
}

export default Billing
