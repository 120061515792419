import React, { useEffect, useState } from 'react'

import './myorder.css'

import Sidebar from './Sidebar';

import { useParams } from 'react-router';

import { useMediaQuery, useModelState } from '../Home/Custom_hooks/custom';
import { Modal } from 'rsuite';
import { ModalFooter } from 'react-bootstrap';

const MyOrders = () => {

    const [matched,setMatched]=useState(false);

//   const [userId,setUserId]=useState('');

const params=useParams();
    const userId=params.userId

//   const redirect=async()=>{
   
//     const response= await fetch('http://localhost:3007/matched') 
//     const data=await response.json();
//       setMatched(data.matched);
// }

// useEffect(()=>{
// redirect();
// })


 
// const fetchUser=async()=>{
//   const response= await fetch('http://localhost:3007/login');
//   var data= await response.json();
//   setUserId(data[0].userId);
//   console.log("userId",userId);

// }
// if(matched===true)
//    fetchUser();


    const isMobile=useMediaQuery('(max-width:700px)');
    const [myOrders,setMyOrders]=useState([]);
    const [isCancled,setIsCancled]=useState(false);
    const [cancledOrder,setCancledOrder]=useState('');

    const {isOpen,open,close}=useModelState();



    useEffect(()=>{
        const fetchOrders= async ()=>{
            const response= await fetch(`http://localhost:3007/placeOrder/${userId}` ,{
                method:"GET",
                headers:{
                    'content-type':'application/json'
                }
            });
            let data= await response.json();
            setMyOrders(data);
        }
        fetchOrders();

    },[userId])
    
    // useEffect(()=>{
    //    fetchOrders()
    // })
 

     const handleOrderCancelation= async(ev)=>{

        setCancledOrder(ev.target.id);
        setIsCancled(true);

        try{
            const response= await fetch(`http://localhost:3007/cancleOrder/${ev.target.id}` ,{
                method:"POST",
                headers:{
                    'content-type':'application/json'
                }
            });
            let data= await response.json();
            console.log(data);
        }
        catch(err){
           console.log(err)
        }
     }
  return <>
    <Sidebar/>
    <div className='userbox' style={isMobile?{}:{marginTop:'8%',marginLeft:'30%'}}>
    <div className='history'>ORDER HISTORY</div>
        {myOrders.map(order=>{
            return <>
             <div key={order._id} className='obox' style={{borderRadius:'0%',height:'27vw'}}>
             <div>
                
             <table style={{marginTop:'2%'}}>
        <tbody>
            <tr>
                <td className='td'>Order Id</td>
                <td className='orderdata' style={{color:'white'}}>{order._id}</td>
            </tr>
            <tr>
                {order.Type==='kg'?
                <>
                <td  className='td' style={{backgroundColor:'lightcyan',border:'.1vw solid cyan'}}>Service</td>
                <td className='orderdata'  style={{backgroundColor:"cyan",border:'.1vw solid blue',color:'black'}}>{order.Service.map(serv=>{
                     return <>
                     <div key={serv.name} style={{fontWeight:'bolder'}} >{serv.service} - {serv.weight} kg</div>
                     </>
                })}</td> 
                </>
                : <>
                <td  className='td' style={{backgroundColor:'lightcyan',border:'.1vw solid cyan'}}>Service</td>
                {/* <td className='orderdata servicedata'  style={{backgroundColor:"cyan",border:'.1vw solid blue',color:'black'}}>
                {order.Service.map(serv=>{
                      return <>
                      <div key={serv.name} style={{fontWeight:'bolder'}} ><tr>{serv.services} - {serv.name}({serv.quantity})</tr></div>
                      </>
                })}
                </td>  */}
                <td className='orderdata servicedata'  style={{backgroundColor:"cyan",border:'.1vw solid blue',color:'black'}}>
                    <button onClick={open} style={{backgroundColor:'green',color:'white',border:'0'}}>See added services</button>
                </td> 
                <Modal  open={isOpen} onClose={close} >
                    <Modal.Header></Modal.Header>
                    <Modal.Body>
                    {order.Service.map(serv=>{
                      return <>
                      <div key={serv.name} style={{fontWeight:'bolder'}} >{serv.services} - {serv.name}({serv.quantity})</div>
                      </>
                })}
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                </Modal>
                </>}
            </tr>
            <tr>
                <td  className='td'  style={{backgroundColor:'lightcyan',border:'.1vw solid cyan'}}>Pick Up Date</td>
                <td className='orderdata' style={{backgroundColor:"cyan",border:'.1vw solid blue',color:'black'}}>{order.PickUpDate}</td>
            </tr>
            <tr>
                <td  className='td'  style={{backgroundColor:'lightcyan',border:'.1vw solid cyan'}}>Pick Up Time</td>
                <td className='orderdata' style={{backgroundColor:"cyan",border:'.1vw solid blue',color:'black'}}>{order.PickUpTime}</td>
            </tr>
            <tr>
                <td  className='td'  style={{backgroundColor:'lightcyan',border:'.1vw solid cyan'}}>Payment Status</td>
                <td className='orderdata' style={{backgroundColor:"cyan",border:'.1vw solid blue',color:'black'}}>{order.PaymentMode?'Pending':'Payment Completed'}</td>
            </tr>
            <tr>
                <td  className='td'  style={{backgroundColor:'lightcyan',border:'.1vw solid cyan'}}>Price</td>
                <td className='orderdata' style={{backgroundColor:"cyan",border:'.1vw solid blue',color:'black'}}>{order.price}</td>
            </tr>
            <tr>
                {/* <td style={{backgroundColor:'rgb(75, 75, 255)',border:'.1vw solid cyan'}}>
                    {isCancled && order._id===cancledOrder?
                     ""
                      :<button className='cancel_order' onClick={handleOrderCancelation} id={order._id} >Cancel Order</button>
                    }
                    </td> */}
                    <td style={{backgroundColor:'rgb(75, 75, 255)',border:'.1vw solid cyan'}}>
                    {order.orderStatus==='Pending'?
                     <button className='cancel_order' onClick={handleOrderCancelation} id={order._id} >Cancel Order</button>
                      :<div style={{color:"white"}}>Order Status </div>
                    }
                    </td>

                {/* <td className='orderdata'>{order.PaymentMode==='pay later'?<button  className={isCancled && order._id===cancledOrder?'cancel_order':'pending'}>{isCancled && order._id===cancledOrder?'Order Cancelled':'Pending' }</button>:''}</td> */}
                <td className='orderdata' style={{backgroundColor:'rgb(75, 75, 255)',border:'.1vw solid cyan'}}><button  className={isCancled && order._id===cancledOrder?'cancel_order':'pending'} style={order.orderStatus==='Cancled'?{backgroundColor:'red',color:'white'}:{backgroundColor:'green',color:'white'}}>{order.orderStatus}</button></td>
                
            </tr>
        </tbody>
    </table>
             </div>
             </div>
             
            </>
        })}
        {/* <div className='histNavBox'>
            <button className='historyNav'>{'<'}  &nbsp;Prev</button>
            <button  className='historyNav'>Next  &nbsp;{'>'}</button>
        </div> */}
    </div>
    </>
}

export default MyOrders
